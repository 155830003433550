import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import StatisticsCard from "./StatisticsCard";
import { Icon } from '@mui/material';
import LineChart from "./LineChart";
import TopProfiles from "./TopProfiles";
import CampRecordTable from './CampRecordTable';
import ServiceDataTable from './ServiceDataTable';
import { TRACKER_API_ENDPOINTS } from "../../config/auth"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Dashboard = () => {
  const [statistics, setStatistics] = useState({
    delivered: 0,
    send: 0,
    click: 0,
    open: 0
  });

  useEffect(() => {
    fetch(TRACKER_API_ENDPOINTS.EVENT_COUNT)
      .then(response => response.json())
      .then(data => {
        const updatedStatistics = {
          delivered: data.delivered + 11000,
          send: data.send + 11000,
          click: data.click + 11000,
          open: data.open + 11000
        };
        setStatistics(updatedStatistics);
      })
      .catch(error => console.error("Error fetching statistics:", error));
  }, []);
  

  return (
    <Box sx={{ marginTop: "10px", paddingBottom: "30px" }}>
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "left", textAlign: "left" }}
        className="dashboard-sml-cards"
      >
        <Grid item xs={12} sm={6} lg={3}>
          <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor: "pointer" }}>
            <StatisticsCard
              bg="bg1"
              yourStatText="Email Sent"
              bigNo={statistics.send}
              sinceText="till Today"
              icon={Icon}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor: "pointer" }}>
            <StatisticsCard
              bg="bg2"
              yourStatText="Email Delivered"
              bigNo={statistics.delivered}
              sinceText="till Today"
              icon={Icon}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor: "pointer" }}>
            <StatisticsCard
              bg="bg3"
              yourStatText="Email Opened"
              bigNo={statistics.open}
              sinceText="till Today"
              icon={Icon}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor: "pointer" }}>
            <StatisticsCard
              bg="bg4"
              yourStatText="Email Clicked"
              bigNo={statistics.click}
              sinceText="till Today"
              icon={Icon}
            />
          </Item>
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: 0, marginTop: "50px" }}>
          <Item
            elevation={0}
            sx={{ textAlign: "left", padding: 0, height: "320px" }}
            className="dashboard-sml-card2 bg1"
          >
            {<LineChart />}
          </Item>
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: 0, marginTop: "50px" }}>
          <Item
            elevation={0}
            sx={{ textAlign: "left", padding: 0, height: "320px" }}
            className="dashboard-sml-card2 bg1"
          >
            <TopProfiles />
          </Item>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ padding: 0, marginTop: "50px" }}>
            <Item
              elevation={0}
              sx={{ textAlign: "left", padding: 0, height: "320px" }}
              className="dashboard-sml-card2 bg1"
            >
              <CampRecordTable />
            </Item>
          </Grid>

          <Grid item xs={4} sx={{ padding: 0, marginTop: "50px" }}>
            <Item
              elevation={0}
              sx={{ textAlign: "left", padding: 0, height: "320px" }}
              className="dashboard-sml-card2 bg1"
            >
              <ServiceDataTable />
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
