import { Box, IconButton, Tooltip, Typography, Button } from "@mui/material";
import { useEffect, useRef } from "react";
import OneIcon from "../../Assets/Icons/oneCircle.svg";
import OneIconChecked from "../../Assets/Icons/oneCircleChecked.svg";
import OneText from "../../Assets/Icons/Contacts.svg";
import TwoIcon from "../../Assets/Icons/twoIcon.svg";
import TwoIconWhite from "../../Assets/Icons/twoIconWhite.svg";
import TwoText from "../../Assets/Icons/twoText.svg";
import ThreeIcon from "../../Assets/Icons/ThreeIcon.svg";
import ThreeIconWhite from "../../Assets/Icons/threeIconWhite.svg";
import ThreeText from "../../Assets/Icons/ThreeText.svg";
import Hamburger from "../../Assets/Icons/hamBurger.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import "./LeftNavigation.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MarketMinderLogo from "../../Assets/Icons/MarketLogo.svg";
import compunnelLogo from "../../Assets/Icons/compunnel-logo.svg";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import crossIcon from "../../Assets/Icons/crossIcon.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import MarketMinderTxt from "../../Assets/Icons/Market_Minder_txt.svg";
import AutomatedIcon from "../../Assets/Icons/AutomatedFollupIcon.svg";
import {DashboardIcon} from "../../Assets/Icons/DashboardIcon";
import MailReviewIcon from "../../Assets/Icons/MailReviewIcon.svg";
import UnsubscribeIcon from "../../Assets/Icons/unsubscribeIcon.svg";
import LogoutIcon from "../../Assets/Icons/LogoutIcon.svg";

const drawerWidth = 179;
const collapsedWidth = 100;
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));



const LeftNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
    setOpen(!open)
  };

 

  const renderDrawer = () => (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: "border-box",
          display: "flex",
        },
      }}
      variant="persistent"
      anchor="left"
      ref={drawerRef}
      open={open}
    >
      <DrawerHeader 
      className="custom-drower-header"
      style={{ marginRight: "20px"}}
      >
        <Tooltip title={"Menu"} placement="right-end">
            <IconButton
              className="desktopMenuIconbtn"
            >
              <MenuIcon style={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
          <Typography className="header-font"> Menu</Typography>
      </DrawerHeader>
      <Divider className="sidebar-devider-main" />
      <Box className="custom-main-list">
        <List className="custom-list">
        <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/dashboard")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/dashboard" ? "#303cad" : "inherit",
              }}
            >
               <DashboardIcon />
              <ListItemText
                primary="Dashboard"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/automated-followUp")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/automated-followUp" ? "#303cad" : "inherit",
              }}
              // onClick={handleDrawerClose}
            >
              <img src={AutomatedIcon} />
              <ListItemText
                primary="Automated Follow Up Settings"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />

          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/mail-review")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/mail-review" ? "#303cad" : "inherit",
              }}
            >
              <img src={MailReviewIcon} />
              <ListItemText primary="Mail Review" className="drower-text" />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />

          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/unsubscribe-followUp")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/unsubscribe-followUp" ? "#303cad" : "inherit",
              }}
            >
              <img src={UnsubscribeIcon} style={{ color: "white" }} />
              <ListItemText
                primary="Unsubscribed Follow Up"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/hubspot")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/hubspot" ? "#303cad" : "inherit",
              }}
            >
              <img src={AutomatedIcon} />
              <ListItemText
                primary="Contact Source"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
        </List>

        <List className="custom-list2">
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={handleLogOut}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              data-testid="logout-btn"
            >
              <img src={LogoutIcon} style={{ color: "white" }} />
              <ListItemText primary="Logout" className="drower-text" />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />

          <ListItem
            disablePadding
            className="custom-listItem-header"
            sx={{ marginBottom: "auto" }}
          >
            <ListItemText
              primary="Version 2.1"
              className="drower-text-2"
              color="white"
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
   <div className="leftNavigationMenu">
    {renderDrawer()}
   </div>
  )
}

export default LeftNavigation
