import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TRACKER_API_ENDPOINTS } from "../../config/auth"

interface ServiceData {
  service: string;
  value: number;
}

const ServiceDataTable = () => {
  const [serviceData, setServiceData] = useState<ServiceData[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchServiceData();
  }, []);

  const fetchServiceData = async () => {
    try {
      const response = await fetch(TRACKER_API_ENDPOINTS.INFRA_COST);
      if (!response.ok) {
        throw new Error('Failed to fetch service data');
      }
      const jsonData = await response.json();
      setServiceData(jsonData);
    } catch (error) {
      console.error('Error fetching service data:', error);
      setError('Failed to fetch service data. Please try again later.');
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="service table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="left" sx={{ fontWeight: 'bold' }}>
              Service Cost
            </TableCell>
          </TableRow>
          <TableRow sx={{ fontWeight: 'bold' }}>
            <TableCell>Service</TableCell>
            <TableCell align="right">Value(ROI)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {error ? (
            <TableRow>
              <TableCell colSpan={2} align="center">{error}</TableCell>
            </TableRow>
          ) : (
            serviceData.map((row, index) => (
              <TableRow key={row.service}>
                <TableCell component="th" scope="row" style={{ fontWeight: index === serviceData.length - 1 ? 'bold' : 'normal' }}>
                  {row.service}
                </TableCell>
                <TableCell align="right" style={{ fontWeight: index === serviceData.length - 1 ? 'bold' : 'normal' }}>${row.value.toFixed(2)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceDataTable;
